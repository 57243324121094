<template>
	<div class="header-tab" v-if="$route.name !== 'Catalyst'">
		<div class="tab-list">
			<div
				@click="setActiveTab('Dao')"
				:class="					
					$route.name === 'Dao' ||
					$route.name === 'Dao-Detail' ||
					$route.name === 'SubmitPage' ||
					$route.name === 'CreateProposal'
						? 'tab-item active'
						: 'tab-item'
				"
			>
				Proposals
			</div>
			<div
				v-if="wallet"
				@click="setActiveTab('Voting-Power')"
				:class="
					$route.name === 'Voting-Power'
						? 'tab-item active'
						: 'tab-item'
				"
			>
				Voting Power
			</div>
			<!-- <div
				@click="setActiveTab('Transparency')"
				:class="
					$route.name === 'Transparency'
						? 'tab-item active'
						: 'tab-item'
				"
			>
				Transparency
			</div> -->
			<div
				v-if="wallet"
				@click="setActiveTab('Activity')"
				:class="
					$route.name === 'Activity' ? 'tab-item active' : 'tab-item'
				"
			>
				Activity
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HeaderTab',
	data() {
		return {
			wallet: this.$store.state.userInfo.wallet_addr,
		}
	},
	watch: {
		'$store.state.userInfo': function (newVal, oldVal) {
			this.wallet = newVal.wallet_addr
		},
	},
	methods: {
		setActiveTab(newTab) {
			if (this.$route.name === newTab) return
			if (newTab === 'Voting-Power') {
				this.$router.push({
					name: newTab,
					params: { address: this.$store.state.userInfo.wallet_addr },
				})
			} else {
				this.$router.push({
					name: newTab,
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.header-tab {
	@include FLEXV(flex-end, center);
	font-family: 'Montserrat';
	height: 100%;
	width: 100%;
	position: relative;
	overflow: auto;
	&::-webkit-scrollbar {
		height: 0px;
	}
	.tab-list {
		display: flex;
		width: 100%;
		max-width: gREm(1180);
		& .tab-item {
			cursor: pointer;
			font-size: gREm(16);
			line-height: gREm(24);
			color: #ffffff;
			padding: gREm(8) gREm(16);
			font-weight: 400;
			white-space: nowrap;
			&.active {
				background: rgba(255, 255, 255, 0.15);
				border-bottom: 4px solid #f87171;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				font-weight: 500;
			}
		}
	}
}
@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}

@include media-max($media_small) {
	// 768
}
</style>
